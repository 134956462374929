import React, { useState } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import useDarkMode from "use-dark-mode"

const SearchBox = styled.div`
  button {
    width: 76px;
    height: 34px;
    /* background: #13c750; */
    background: ${props => props.background};
    border-radius: 17px;
    font-size: 12px;
  }

  input {
    min-width: 350px;
  }

  @media screen and (max-width: 1023px) {
    input {
      min-width: 100px;
    }

    button {
    width: 50px;
    height: 30px;
    }
  }

  @media screen and (max-width: 350px) {
    input {
      width: 50px;
    }

    button {
    width: 40px;
    }
  }
`

const SelectBox = styled.div`
  margin-right: 50px;

  @media screen and (max-width: 1023px) {
      margin-right: 10px;
  }

  @media screen and (max-width: 350px) {
     font-size: 11px;
  }
`

const SelectBoxMobile = styled.a`
  font-size: 12px;
  border: 1px solid #c9c9c9;
  border-radius: 6px;
  width: 150px;
  height: 40px;

  @media screen and (max-width: 350px) {
    width: 130px;
  }

  @media screen and (max-width: 280px) {
    font-size: 10px;
    width: 110px;
  }
`

const CategoryBar = ({
  categories,
  currentCate,
  handleCate,
  isSearch,
  handleSearch,
  handleSearchText,
  frontmatter,
  theme
}) => {
  const [isShowCate, setIsShowCate] = useState(false)
  const [searchText, setSearchText] = useState("")
  const { value } = useDarkMode()

  const handleClick = () => {
    setIsShowCate(!isShowCate)
  }
  return (
    <div className="flex items-center justify-around cate-bar">
      {!isSearch &&
        categories
          .filter(item => item.text !== "Search")
          .map((value, index) => (
            <div
              key={index}
              className="flex items-center justify-center flex-1 h-full md:hidden"
              style={{
                cursor: "pointer",
                borderBottom:
                  value.text === currentCate ? `4px solid ${theme.light_theme.background_color.third}` : "none",
                fontWeight: value.text === currentCate ? "bold" : "normal",
              }}
              onClick={() => handleCate(value.text)}
            >
              <i className={`icon cate ${value.icon}`}></i>
              {value.text === "All Games"
                ? value.text
                : value.text.charAt(0).toUpperCase() + value.text.slice(1)}
            </div>
          ))}
      {categories.length && (
        <div
          className="flex items-center justify-center flex-1 h-full"
          style={{
            borderBottom: isSearch ? `4px solid ${theme.light_theme.background_color.third}` : "none",
          }}
        >
          <div
            className={`flex items-center justify-center h-full relative ${!isSearch && "flex-1"
              }`}
            style={{
              cursor: "pointer",
            }}
          >
            <div className="md:hidden" onClick={handleSearch}>
              <i
                className={`icon cate ${categories[categories.length - 1].icon}`}
              ></i>
            </div>
            <div className="md:hidden" onClick={handleSearch}>
              {!isSearch && categories[categories.length - 1].text}
            </div>
            {isSearch && (
              <SelectBox
                className="relative flex items-center select-box"
                onClick={handleClick}
              >
                {currentCate} <span className="block"></span>
                <div
                  className={`select-list absolute ${isShowCate ? "block" : "hidden"
                    }`}
                >
                  {categories
                    .filter(
                      item =>
                        item.text !== "SEARCH" && item.text !== currentCate
                    )
                    .map((value, index) => (
                      <div
                        key={index}
                        className="flex items-center flex-1 h-full"
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={() => handleCate(value.text)}
                      >
                        {value.text}
                      </div>
                    ))}
                </div>
              </SelectBox>
            )}
            {!isSearch && (
              <SelectBoxMobile
                className="relative flex items-center select-box lg:hidden"
                onClick={handleClick}
              >
                {categories
                  .filter(
                    item =>
                      item.text !== "SEARCH" && item.text === currentCate
                  )
                  .map((value, index) => (
                    <div
                      key={index}
                      className="flex items-center flex-1 h-full ml-2"
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => handleCate(value.text)}
                    >
                      <i className={`icon cate ${value.icon}`}></i>
                      {/* {value.text} */}
                    </div>
                  ))}
                {currentCate}
                <div className='ml-2 mr-2'>
                  <span className="block"></span>
                </div>
                <div
                  className={`select-list absolute ${isShowCate ? "block" : "hidden"
                    }`}
                >
                  {categories
                    .filter(
                      item =>
                        item.text !== "SEARCH" && item.text !== currentCate
                    )
                    .map((value, index) => (
                      <div
                        key={index}
                        className="flex items-center flex-1 h-full"
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={() => handleCate(value.text)}
                      >
                        <i className={`icon cate ${value.icon}`}></i>
                        {value.text.charAt(0).toUpperCase() + value.text.slice(1)}
                      </div>
                    ))}
                </div>
              </SelectBoxMobile>
            )}
            {!isSearch && (
              <SelectBoxMobile
                className="relative flex items-center ml-4 lg:hidden"
                onClick={handleSearch}
              >
                <i
                  className={`icon cate ${categories[categories.length - 1].icon} ml-5`}
                >
                </i>
                {categories[categories.length - 1].text}
              </SelectBoxMobile>
            )}
          </div>
          {isSearch && (
            <SearchBox className="flex items-center justify-center"
              background={theme.light_theme.background_color.third}
            >
              <div>
                <input
                  className="search-input"
                  type="text"
                  placeholder={frontmatter.search_label}
                  style={{ background: `${value ? theme.dark_theme.background_color.secondary : theme.light_theme.background_color.secondary }` }}
                  onInput={({ target: { value } }) => setSearchText(value)}
                />
              </div>
              <div>
                <span
                  className="relative block close-icon"
                  {...(isSearch && { onClick: handleSearch })}
                ></span>
              </div>
              <div>
                <button
                  className="flex items-center justify-center text-white"
                  onClick={() => handleSearchText(searchText)}
                >
                  {frontmatter.search_button}
                </button>
              </div>
            </SearchBox>
          )}
        </div>
      )}
    </div>
  )
}

CategoryBar.propTypes = {
  categories: PropTypes.array,
  currentCate: PropTypes.string,
  handleCate: PropTypes.func,
  isSearch: PropTypes.bool,
  handleSearch: PropTypes.func,
  handleSearchText: PropTypes.func,
}

export default CategoryBar
