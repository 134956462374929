import React, { useState } from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import useInterval from "@use-it/interval"
import PlayBanner from "~/images/icon_play_banner.svg"
import PlayUrlContext from "~/context/play-url"

const DotWrapper = styled.div`
  right: 80px;
  bottom: 15px;
  padding: 8px 12px;
  background: rgba(255, 255, 255, 0.45);
  border-radius: 16px;
  opacity: 1;
  backdrop-filter: blur(30px);
`

const DotItem = styled.a`
  width: 40px;
  height: 40px;
  background: #fff;
  border-radius: 50%;

  &:not(:first-child) {
    margin-left: 12px;
  }

  &.grey {
    filter: grayscale(100%);
  }
`

const BannerText = styled.p`
  font-size: 22px;
  left: 80px;
  bottom: 120px;

  @media screen and (max-width: 1023px) {
    margin-top: 30px;
    font-size: 17px;
    top: 115px;
    left: 55px;
  }

  @media screen and (max-width: 750px) {
    top: 75px;
    left: 60px;
  }

  @media screen and (max-width: 600px) {
    font-size: 12px;
    top: 60px;
    left: 40px;
  }

  @media screen and (max-width: 450px) {
    margin-top: 35px;
    font-size: 10px;
    top: 25px;
    left: 30px;
  }

  @media screen and (max-width: 350px) {
    margin-top: 25px;
  }
`

const BannerButton = styled.a`
  font-size: 19px;
  /* color: #43ea7e !important; */
  color: ${props => props.fontColor} !important;
  left: 80px;
  bottom: 60px;
  width: 170px;
  height: 50px;
  /* border: 2px solid #79e689; */
  border: 2px solid ${props => props.border};
  border-radius: 6px;

  > img {
    width: 26px;
    height: 26px;
  }

  @media screen and (max-width: 1023px) {
    margin-top: 30px;
    font-size: 15px;
    top: 150px;
    width: 130px;
    height: 40px;
    left: 55px;

    > img {
      width: 23px;
      height: 23px;
    }
  }

  @media screen and (max-width: 750px) {
    font-size: 12px;
    top: 100px;
  }

  @media screen and (max-width: 600px) {
    font-size: 12px;
    top: 80px;
    width: 110px;
    height: 30px;
    left: 40px;

    > img {
      width: 20px;
      height: 20px;
    }
  }

  @media screen and (max-width: 450px) {
    margin-top: 40px;
    font-size: 10px;
    top: 40px;
    width: 80px;
    left: 30px;
    height: 25px;

    > img {
      width: 15px;
      height: 15px;
    }
  }

  @media screen and (max-width: 350px) {
    font-size: 9px;
    top: 28px;
    width: 80px;
    left: 30px;
    height: 25px;

    > img {
      width: 15px;
      height: 15px;
    }
  }
`

const Slider = ({ bannerList, theme }) => {
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0)
  useInterval(() => {
    setCurrentSlideIndex(
      bannerList.length - 1 === currentSlideIndex ? 0 : currentSlideIndex + 1
    )
  }, 4000)
  const handleDot = (e, index) => {
    e.preventDefault()
    setCurrentSlideIndex(index)
  }
  return (
    <PlayUrlContext.Consumer>
      {({ guessUrl }) => (
        <div className="relative">
          {bannerList.map((value, index) => (
            <div
              key={index}
              style={{
                display: currentSlideIndex === index ? "block" : "none",
              }}
              className="fade"
            >
              <img src={`/${value.frontmatter.image}`} alt="" />
              <BannerText className="absolute text-white">
                {value.frontmatter.banner_text ||
                  `Available now on iOS, Andoid and Desktop!`}
              </BannerText>
              <BannerButton
                className="absolute flex items-center justify-evenly"
                href={guessUrl}
                target="_blank"
                border={theme.light_theme.background_color.third}
                fontColor={theme.light_theme.font_color.third}
              >
                <img src={PlayBanner} alt="" />
                {value.frontmatter.button_text || `TRY NOW`}
              </BannerButton>
            </div>
          ))}
          <DotWrapper className="absolute flex md:hidden">
            {bannerList.map((value, index) => (
              <DotItem
                key={index}
                onClick={e => handleDot(e, index)}
                href="#"
                className={index !== currentSlideIndex && "grey"}
              >
                <img src={`/${value.frontmatter.dot_thumbnail}`} alt="" />
              </DotItem>
            ))}
          </DotWrapper>
        </div>
      )}
    </PlayUrlContext.Consumer>
  )
}

Slider.propTypes = {
  bannerList: PropTypes.array,
  playUrl: PropTypes.string,
}

export default Slider
